import { getStockList } from 'api/stock';
import { Cars, Facets } from 'data/types';
import { createContext, useContext, useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useQueryClient } from 'react-query';
import { SortProps } from 'routers/types';

interface ObservedQueryContextProps {
    data: any; // Replace 'any' with the type of your data
    facets: { [key: string]: Facets[] } | undefined;
    isLoading: boolean;
    error: any; // Replace 'any' with the type of your error object
    isFetching: boolean;
    refetch: () => Promise<void>;
    updateFilters: (filters: SortProps) => void;
    sortOptions: SortProps;
    isFetchingNextPage: boolean;
    fetchNextPage: () => Promise<any>;
    hasNextPage: boolean;
}

const ObservedQueryContext = createContext<ObservedQueryContextProps>(
    {} as ObservedQueryContextProps
);

export const useObservedQuery = (): ObservedQueryContextProps => {
    return useContext(ObservedQueryContext);
};

export const ObservedQueryProvider = ({ children }: any) => {
    const queryClient = useQueryClient();

    const [sort, setSort] = useState<SortProps>(() => {
        const savedSort = sessionStorage.getItem('filters');
        if (savedSort) return JSON.parse(savedSort);
        return {
            sort: 'totalPriceDesc',
        };
    });


    const [facets, setFacets] = useState<{ [key: string]: Facets[] } | undefined>();

    const { isLoading, error, data, isFetching, refetch, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery({
        queryKey: ['cars', sort],
        queryFn: ({ pageParam = 1 }) => {
            const cached = queryClient.getQueryData(['cars', sort]) || undefined;
            if (pageParam > 1) {
                // check if the page is already cached
                const cachedPage = cached?.pages.length
                if (cachedPage && cachedPage >= pageParam) {
                    return cached?.pages[pageParam - 1];
                }
                return getStockList(sort, pageParam);
            }
            if (cached && sort.sort !== "myFavourites") return { ...cached.pages[0] };
            else return getStockList(sort, pageParam)
        },
        getNextPageParam: (lastPage: { results: Cars[], totalResults: number }, pages: any[]) => {
            const maxPages = Math.ceil(lastPage.totalResults / 20);
            const nextPage = pages.length + 1;
            return nextPage <= maxPages ? nextPage : undefined;
        },
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        cacheTime: 2 * 60 * 1000, // 2 minutes
    });

    // Infinite scroll
    useEffect(() => {
        let fetching = false;
        const onScroll = async (event: any) => {
            if (window.location.pathname !== '/cars') return;

            const { scrollHeight, scrollTop, clientHeight } =
                event.target.scrollingElement;

            if (!fetching && scrollHeight - scrollTop <= clientHeight * 3.3) {
                fetching = true;
                if (hasNextPage) await fetchNextPage();
                fetching = false;
            }
        };

        document.addEventListener("scroll", onScroll);
        return () => {
            document.removeEventListener("scroll", onScroll);
        };
    }, [hasNextPage]);


    useEffect(() => {
        if (sort.keywords) return; // don't update facets when searching
        if (data && data?.pages[0]?.facets) setFacets(data?.pages[0]?.facets);
    }, [data]);

    useEffect(() => {
        sessionStorage.setItem('filters', JSON.stringify(sort));
    }, [sort]);

    const updateFilters = (filters: SortProps) => {
        if (filters.keywords) filters.keywords = filters.keywords.trim();

        // if there is any other filter, with keywords, remove keywords
        if (Object.keys(filters).length > 3 && filters.keywords) delete filters.keywords;

        // if sort is myFavourites, remove all other filters
        if (filters.sort === "myFavourites") {
            filters = {
                sort: "myFavourites"
            }
        }

        // reset page scroll to top
        sessionStorage.removeItem("scrollPosition");

        setSort(filters);
    };

    const contextValue = {
        data,
        facets,
        updateFilters,
        refetch,
        isLoading,
        isFetching,
        error,
        sortOptions: sort,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
    }

    return (
        <ObservedQueryContext.Provider value={contextValue}>
            {children}
        </ObservedQueryContext.Provider>
    );
};
