import axios from 'axios'
import { createToken, getToken } from './auth'
import { SortProps } from 'routers/types';
import { Cars, FinanceRequest, FinanceResponse } from 'data/types';
import { getLikedCars } from 'utils/utils';

export const getStockList = async (filters: SortProps, pageParam: any = 1) => {
    try {
        let auth = getToken();
        if (!auth) auth = await createToken();
        // if auth is still undefined, return empty array
        if (!auth?.access_token) {
            return {
                results: [],
                facets: {},
                totalResults: 0
            }
        }

        const API_URL = process.env.REACT_APP_API_URL;

        if (filters.sort && filters.sort === "myFavourites") {
            const liked = getLikedCars()
            if (liked.length > 0) filters.searchIds = liked
        } else filters.searchIds = undefined

        const details = await axios.post(`${API_URL}/stock/search?page=${pageParam}`, filters, {
            headers: {
                "Authorization": `Bearer ${auth?.access_token}`,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        });

        return details.data
    } catch (e) {
        console.log(e)
        return {
            results: [],
            facets: {},
            totalResults: 0
        }
    }
}

export const getStockbyId = async (id: string) => {
    let auth = getToken();
    if (!auth) auth = await createToken();
    const API_URL = process.env.REACT_APP_API_URL;

    const details = await axios.post(`${API_URL}/stock/search`, {
        searchId: id
    }, {
        headers: {
            "Authorization": `Bearer ${auth?.access_token}`,
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
    });

    const features = await axios.get(`${API_URL}/features/${id}`, {
        headers: {
            "Authorization": `Bearer ${auth?.access_token}`,
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
    });

    return [details.data, features.data]
}

export const getFinance = async (data: FinanceRequest): Promise<{ hp: FinanceResponse[], pcp: FinanceResponse[] }> => {
    let auth = getToken();
    if (!auth) auth = await createToken();
    const API_URL = process.env.REACT_APP_API_URL;


    const details = await axios.post(`${API_URL}/finance`, data, {
        headers: {
            "Authorization": `Bearer ${auth?.access_token}`,
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
    });

    return details.data
}

export const getFacets = async (filters?: SortProps) => {
    // check if facets are in local storage
    const facets = localStorage.getItem('facets')
    const timestamp = localStorage.getItem('facets_timestamp')

    if (facets && timestamp) {
        const now = new Date().getTime()
        const diff = now - parseInt(timestamp)
        const minutes = Math.floor(diff / 1000 / 60)
        if (minutes < 60) {
            return JSON.parse(facets)
        }
    }

    let auth = getToken();
    if (!auth) auth = await createToken();
    const API_URL = process.env.REACT_APP_API_URL;

    const data = await axios.get(`${API_URL}/taxonomy`, {
        headers: {
            "Authorization": `Bearer ${auth?.access_token}`,
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
    })

    if (data.data && data.status === 200) {
        // store in local storage
        localStorage.setItem('facets', JSON.stringify(data.data))
        // time stamp
        localStorage.setItem('facets_timestamp', JSON.stringify(new Date().getTime()))
        return data.data
    }

    return []
}

export const generateDescription = async (carInfo: Cars) => {
    const OPEN_AI_API_KEY = process.env.REACT_APP_OPEN_AI_API_KEY;
    const prompt = generatePrompt(carInfo)

    const carId = carInfo.meta.carId; // Assuming you have an ID property in carInfo

    // Check if the description for this car is already stored
    const storedDescription = localStorage.getItem(`carDescription_${carId}`);
    if (storedDescription) {
        // Return the stored description
        return storedDescription;
    }

    const data = {
        "model": "text-davinci-003",
        "temperature": 1,
        "max_tokens": 256,
        "top_p": 1,
        "frequency_penalty": 0,
        "presence_penalty": 0,
        "prompt": prompt,
    }

    try {
        const response = await axios.post('https://api.openai.com/v1/completions', data, {
            headers: {
                "Authorization": `Bearer ${OPEN_AI_API_KEY}`,
                "Content-Type": "application/json"
            }
        })

        const generatedText = response.data.choices[0].text;

        // const cleanGeneratedText = generatedText.replace(/\n/g, ' ').replace(/\s+/g, ' ');
        // // Store the cleaned generated text in local storage
        // localStorage.setItem(`carDescription_${carId}`, cleanGeneratedText);

        return generatedText;
    } catch (error) {
        // Handle errors gracefully (e.g., network issues, API errors)
        console.error('Error generating description:', error);
        return 'No description available';
    }
}

export const generatePrompt = (carInfo: Cars) => {
    const {
        make,
        model,
        transmissionType,
        fuelType,
        attentionGrabber,
        technicalDetails: {
            engineTorqueNM,
            topSpeedMPH,
            engineCapacityCC,
            enginePowerBHP,
            zeroToSixtyMPHSeconds,
        }
    } = carInfo;

    const specs = [
        make && model ? `${make} ${model}` : null,
        transmissionType ? `${transmissionType}` : null,
        fuelType ? `${fuelType}` : null,
        engineTorqueNM ? `${engineTorqueNM} NM` : null,
        topSpeedMPH ? `${topSpeedMPH} MPH` : null,
        engineCapacityCC ? `${engineCapacityCC} Litre` : null,
        enginePowerBHP ? `${enginePowerBHP} BHP` : null,
        zeroToSixtyMPHSeconds ? `Zero to 60 in ${zeroToSixtyMPHSeconds} seconds` : null,
    ].filter(Boolean).join(', ');

    const prompt = `Write a description for a car with the following specs: ${specs}.
    Use the following attention grabber: ${attentionGrabber}.
    Make it consumer-friendly, within 3 sentence short, and add some personality to it. Keep it in relation to UK english.`;

    return prompt;
};
