import { AuthToken } from "routers/types";

let tokenState: AuthToken | undefined;

export const getToken = (): AuthToken | undefined => {
    // Get the cookie
    try {
        const cookies = document.cookie.split(';');
        let token = null;
        let expires = null;

        for (const cookie of cookies) {
            const [name, value] = cookie.trim().split('=');
            if (name === 'atTkn') {
                token = decodeURIComponent(value);
            } else if (name === 'atTknExp') {
                expires = Number(value);
            }
        }

        if (!token || !expires) {
            // Token or expiration not found in cookies
            return;
        }

        // parse cookie
        tokenState = {
            access_token: decodeURIComponent(token),
            expires: Number(expires),
        }

        // if token already exists and is not expired, return it
        if (!tokenState) return
        if (tokenState.expires && new Date(tokenState.expires * 1000) > new Date()) return tokenState
    } catch (e) {
        console.log(e);
        return;
    }
}


export const createToken = async () => {
    try {

        const token = getToken()
        if (token) return token

        let API_KEY = process.env.REACT_APP_API_KEY as string;
        let API_SECRET = process.env.REACT_APP_API_SECRET as string;

        const options = {
            key: API_KEY,
            secret: API_SECRET
        };
        const API_URL = process.env.REACT_APP_API_URL;

        const response = await fetch(`${API_URL}/auth`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: new URLSearchParams(options).toString(),
            credentials: 'include'
        })

        const data = await response.json()

        if (response.status !== 200) {
            console.error(data.code, data.message);
            throw new Error(`API request failed with status: ${response.status}`);
        }

        if (data.access_token) tokenState = data
        return data
    } catch (error) {
        console.error('Error while creating token:', error);
        return { error: 'An error occurred while creating the token.' };
    }
}


