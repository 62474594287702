export const converToNumber = (str: string) => {
    // convert string to number and format number
    return Number(str).toLocaleString();
}

export const generateKey = (pre: string) => {
    return `${pre}_${new Date().getTime()}`;
}

export const convertToNumber = (str: string) => {
    if (!str) return 0;
    // remove all comma
    const strWithoutComma = str.replace(/,/g, '');
    // convert string to number and format number
    return Number(strWithoutComma);
}

export const addCarToLikedCars = (carId: string) => {
    const likedCars = getLikedCars();

    if (likedCars.includes(carId)) {
        // remove carId from likedCars
        const newLikedCars = likedCars.filter((id: string) => id !== carId);
        localStorage.setItem('likedCars', JSON.stringify(newLikedCars));
        return;
    }

    likedCars.push(carId);
    localStorage.setItem('likedCars', JSON.stringify(likedCars));
}

export const getLikedCars = () => {
    const likedCars = localStorage.getItem('likedCars');
    if (likedCars) {
        return JSON.parse(likedCars);
    }
    return [];
}

export const createUrlString = (make: string, model: string, carId: string) => {
    // lower case and replace space with dash
    const makeLowerCase = make.toLowerCase().replace(/ /g, '-');
    const modelLowerCase = model.toLowerCase().replace(/ /g, '-');
    return `${makeLowerCase}-${modelLowerCase}-${carId}`;
}

export const getCarIdFromUrl = (url: string) => {
    const carId = url.split('-').pop();
    return carId;
}
